<template>
  <div id="minichallenges">
    <h3>Minichallenges</h3>
    <div v-if="!loaded || !loadedHasPrompts" class="loader">
      <div class="loading loading-lg"></div>
    </div>

    <template v-else>
      <div class="scroll-top" @click="scrollToTop">(^)</div>

      <div>
        Minichallenges listed under a username indicate which minichallenges
        that participant has signed up for; this data is crowdsourced and may
        contain errors.
      </div>

      <div class="mt-2 mb-2">
        <button class="btn" @click="showAdd = !showAdd">
          Toggle Form To Add A User
        </button>

        <add-minichallenge v-if="showAdd"></add-minichallenge>
      </div>

      <div class="divider text-center" data-content="Filters"></div>

      <div>
        <options :minichallenge="true"></options>

        <button
          v-for="mc in minichallenges"
          :class="['btn btn-c', { 'btn-primary': selectedChallenge === mc.id }]"
          @click="setChallenge(mc.id)"
          :key="mc.id + 'selector'"
        >
          {{ mc.name }}
        </button>
      </div>
      <pagination
        :options="options"
        :pagination="pagination"
        @page="page"
      ></pagination>

      <div v-if="updating" class="loader">
        <div class="loading loading-lg"></div>
      </div>

      <table>
        <thead>
          <tr>
            <td>Fandom</td>
            <td>Requests</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(fandom, index) in filtered"
            :key="fandom['.key']"
            :class="{ odd: index % 2 !== 0 }"
          >
            <td class="fandom" data-label="Fandom">
              {{ fandom.name }}
              <!-- <router-link :to="`/fandom/${fandom['.key']}`">
                {{ fandom.name }} </router-link
              >&nbsp; -->
              <!-- <button
              v-if="hasLetters || isModIn"
                class="bookmark"
                @click="toggle(fandom)"
              >
                <span v-if="hasBookmark(fandom)" class="fas fa-heart"></span>
                <span v-else class="far fa-heart"></span>
              </button> -->
              <div class="meta">
                <span class="category meta-tag" v-if="!options.hideCategory">{{
                  fandom.category.join(", ")
                }}</span>
              </div>
            </td>
            <td>
              <template v-if="!selectedChallenge">
                <div
                  v-for="(ch, label) in challenges[fandom['.key']]"
                  :key="label + fandom['.key']"
                >
                  <!-- TODO: MAP NAME -->
                  <strong>{{ getChallengeName(label) }}</strong>
                  <ul>
                    <li v-for="entry in ch" :key="entry.username + label">
                      <a :href="entry.url">{{ entry.username }}</a> (<a
                        href="#"
                        @click.prevent="
                          $router.push({ path: `/user/${entry.username}` })
                        "
                        >Signup</a
                      >)
                    </li>
                  </ul>
                </div>
              </template>
              <div v-else :key="label + fandom['.key']">
                <ul>
                  <li
                    v-for="entry in challenges[fandom['.key']][
                      selectedChallenge
                    ]"
                    :key="entry.username + label"
                  >
                    <a :href="entry.url">{{ entry.username }}</a> (<a
                      href="#"
                      @click.prevent="
                        $router.push({ path: `/user/${entry.username}` })
                      "
                      >Signup</a
                    >)
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination
        :options="options"
        :pagination="pagination"
        @page="page"
      ></pagination>
    </template>
  </div>
</template>

<script>
import Options from "../components/options.vue";
import PromptTable from "../components/promptTable.vue";
import Pagination from "../components/pagination.vue";
import AddMinichallenge from "../components/add-minichallenge.vue";
import utils from "../components/utils.js";
// import challenges from "../data/minichallenges.json";
// third party
import { latinise } from "voca";
import { filter, slice, sortBy, each, includes, find } from "lodash-es";
import { mapGetters } from "vuex";
import axios from "axios";
import { minichallenges } from "../data/minichallengeMeta";

// Remove english articles from fandom names
function removeArticlesCompare(o) {
  if (!o) {
    return;
  }
  const regex = /^(the\s|a\s|an\s)/i;
  if (!o.name) {
    return o;
  }
  return o.name.toLowerCase().replace(regex, "");
}

export default {
  name: "home",
  components: {
    AddMinichallenge,
    Pagination,
    Options
  },
  created() {
    this.updateFilter();
  },
  beforeMount() {
    axios
      .get("https://tagset2023-default-rtdb.firebaseio.com/minichallenges.json")
      .then(res => {
        if (res.data) {
          this.challenges = res.data || {};
        }
      });
  },
  data() {
    return {
      showAdd: false,
      selectedChallenge: undefined,
      minichallenges,
      tableComponent: PromptTable,
      filtered: [],
      challenges: {},
      updating: true,
      sticky: false,
      pagination: {
        first: 0,
        last: 250,
        size: 250,
        total: 0
      }
    };
  },
  computed: {
    ...mapGetters([
      "isModIn",
      "bookmarks",
      "fandoms",
      "hasPrompts",
      "loadAll",
      "loaded",
      "loadedHasPrompts",
      "options",
      "promptmarks",
      "prompts",
      "unlock",
      "user"
    ])
  },
  watch: {
    fandoms() {
      this.pagination.first = 0;
      this.pagination.last = this.pagination.size;
      this.updateFilter();
    },
    // Deep watching doesn't work
    // Reset pagination when the filters change
    "options.filter.term": function() {
      this.updateFilter(true);
    },
    "options.filter.category": function() {
      this.updateFilter(true);
    },
    "options.onlyBookmarks": function(val) {
      this.updateFilter(true);
    },
    loaded() {
      this.updateFilter();
    },
    selectedChallenge() {
      this.updateFilter(true);
    }
  },
  methods: {
    ...utils,
    getChallengeName(id) {
      return find(this.minichallenges, mc => mc.id == id).name;
    },
    setChallenge(id) {
      if (this.selectedChallenge === id) {
        this.selectedChallenge = undefined;
      } else {
        this.selectedChallenge = id;
      }
    },
    updateFilter(resetPagination) {
      this.updating = true;

      if (resetPagination) {
        this.pagination.first = 0;
        this.pagination.last = this.pagination.size;
      }

      // Do not let pagination go below 0
      if (this.pagination.first < 0) {
        this.pagination.first = 0;
      }

      // Remove fandoms that have no names
      let arr = this.fandoms;

      // Case: apply filters
      arr = filter(arr, o => {
        return this.challenges[o[".key"]];
      });

      if (this.selectedChallenge) {
        arr = filter(arr, o => {
          return this.challenges[o[".key"]][this.selectedChallenge];
        });
      }

      if (this.options.onlyBookmarks) {
        const bookmarkedFandoms = [];
        each(this.bookmarks, b => {
          bookmarkedFandoms.push(b.name);
        });

        arr = filter(arr, o => {
          return includes(bookmarkedFandoms, o.name);
        });
      }

      if (this.options.filter.category.length) {
        arr = filter(arr, o => {
          return includes(o.category, this.options.filter.category);
        });
      }

      if (this.options.filter.term.length) {
        arr = filter(arr, o => {
          return (
            latinise(o.name)
              .toLowerCase()
              .indexOf(this.options.filter.term.toLowerCase()) > -1
          );
        });
      }

      // Set pagination.last if the filtered array ends up being smaller
      // or if loadAll is turned on
      if (arr.length < this.pagination.last || this.options.loadAll) {
        this.pagination.last = arr.length;
      }

      // Slice search word and category filters by name without
      // regard for category
      if (
        this.options.filter.term.length ||
        this.options.filter.category.length
      ) {
        this.filtered = slice(
          sortBy(arr, [removeArticlesCompare]),
          this.pagination.first,
          this.pagination.last
        );

        this.pagination.total = arr.length;

        setTimeout(() => {
          this.updating = false;
        }, 100);

        return;
      }

      // Otherwise, sort by category before slicing
      this.filtered = slice(
        sortBy(arr, ["category", removeArticlesCompare]),
        this.pagination.first,
        this.pagination.last
      );
      this.pagination.total = arr.length;

      setTimeout(() => {
        this.updating = false;
      }, 100);
    },
    async page(start) {
      this.pagination.last = start + this.pagination.size;
      this.pagination.first = start;

      this.updateFilter();
      this.scrollToTop();
    },
    scrollToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }
};
</script>

<style lang="scss">
.btn-c {
  margin-right: 5px;
  margin-bottom: 5px;
}
</style>
