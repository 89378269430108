<template>
  <div class="col-4 column col-sm-12">
    <label class="form-label" for="fandom">Fandom {{ number }}</label>
    <span v-if="fandom.name">
      {{ fandom.name }}
      <button class="btn btn-clear" @click="removeFandom"></button>
    </span>

    <template v-if="!fandom.name">
      <input
        :class="['form-input mb-2', { 'is-error': hasError }]"
        type="text"
        placeholder="Search..."
        @keyup="autocomplete"
        @keydown.enter.stop="select"
        @keydown.down="next"
        @keydown.up="prev"
        v-model="term"
      />

      <div v-if="options.length">
        <strong class="mt-2">Available Fandoms</strong>
        <ul>
          <li
            :class="['option c-hand', { focused: i === selectedIndex }]"
            v-for="(option, i) in options"
            :key="option['.key']"
            @mouseenter="selectedIndex = i"
            @mouseleave="selectedIndex = -1"
            @click="select"
            v-html="highlight(option.name, 'f')"
          ></li>
        </ul>
      </div>
    </template>

    <p class="msg" v-if="msg">{{ msg }}</p>
  </div>
</template>

<script>
import { latinise } from "voca";
import axios from "axios";
import { filter, difference, toArray } from "lodash-es";
import { mapGetters } from "vuex";
export default {
  props: {
    reset: {
      type: Boolean,
      default: false,
    },
    savedData: {
      type: Object,
      default() {
        return null;
      },
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    fandoms: {
      type: Array,
      default() {
        return [];
      },
    },
    number: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      msg: "",
      term: "",
      fandom: {},
      options: [],
      selectedIndex: -1,
    };
  },
  computed: {
    ...mapGetters({ characters: "characters", allFandoms: "fandoms" }),
  },
  watch: {
    reset(val) {
      if (!val) return;
      (this.msg = ""),
        (this.term = ""),
        (this.options = []),
        (this.fandom = {}),
        (this.selectedIndex = -1);
    },
    term(val, oldVal) {
      if (val !== oldVal) {
        this.selectedIndex = -1;
        this.msg = null;
      }
    },
    fandom: {
      deep: true,
      handler() {
        this.update();
      },
    },
    savedData: {
      deep: true,
      handler() {
        this.loadSaved();
      },
    },
  },
  beforeMount() {
    if (this.savedData !== null) {
      this.loadSaved();
    }
  },
  methods: {
    loadSaved() {
      this.fandom = this.allFandoms[this.savedData.key];
      this.autocomplete();
    },
    update() {
      this.$emit("update", {
        fandom: {
          name: this.fandom.name,
          ".key": this.fandom[".key"],
        },
        characters: this.chars,
      });
    },
    autocomplete() {
      if (!this.term.length && !this.fandom.name) {
        this.options = [];
        return;
      }

      if (!this.fandom.name) {
        this.options = filter(this.fandoms, (o) => {
          return (
            latinise(o.name).toLowerCase().indexOf(this.term.toLowerCase()) > -1
          );
        });
        return;
      }
    },
    removeFandom() {
      this.fandom = "";
      this.chars = [];
      this.options = [];
      this.selectedIndex = -1;
      this.term = "";
      this.msg = "";
    },
    highlight(option) {
      if (!option) {
        return;
      }
      // highlight any char
      const regex = new RegExp(this.term, "ig");
      return option.replace(regex, "<strong>$&</strong>");
    },
    select(type) {
      if (
        this.selectedIndex < 0 ||
        this.selectedIndex > this.options.length - 1
      ) {
        this.msg = "You must select from the available options!";
        return;
      }

      this.show = null;
      this.fandom = this.options[this.selectedIndex];
      this.term = "";
      const fandomKey = this.fandom[".key"];
      this.options = [];

      return;
    },
    next() {
      if (this.selectedIndex === this.options.length - 1) {
        return;
      }
      this.selectedIndex++;
    },
    prev() {
      if (this.selectedIndex <= 0) {
        this.selectedIndex = -1;
      }
      this.selectedIndex--;
    },
  },
};
</script>

<style lang="scss" scoped>
label,
.focused {
  font-weight: bold;
}

option {
  padding: 3px 0;
  &:disabled:hover {
    font-weight: normal;
    cursor: not-allowed;
  }
  &:hover {
    font-weight: bold;
  }
}

.nominated-characters {
  margin-top: 1em;
}

ul {
  list-style-type: none;
  margin: 0;
}
</style>
