const minichallenges = [
  { name: "2F1", id: "twoforone" },
  //   { name: "3 Turtle Doves", id: "threeturtledoves" },
  //   { name: "And A Partridge", id: "andapartridge" },
  { name: "Interactive Fiction", id: "if" },
  { name: "Crueltide", id: "crueltide" },
  { name: "Queering The Tide", id: "queering" },
  //   { name: "Jewltide", id: "jewltide" },
  //   { name: "MTYG", id: "mtyg" },
  //   { name: "Multilingyule", id: "multilingyule" },
  { name: "Wrapping Paper", id: "wrappingpaper" },
  //   { name: "Yule Be First", id: "yulebefirst" },
  { name: "Season's Treatings", id: "treatings" },
  { name: "Someday My Fic Will Come", id: "someday" },
  { name: "Transtide", id: "transtide" },
  { name: "Yuleporn", id: "yuleporn" }
  //   { name: "Yulebuilding", id: "yulebuilding" },
  //   { name: "Yuletunes", id: "yuletunes" }
];

export { minichallenges };
