<template>
  <div class="mt-2">
    <h4>Add A User To A Minichallenge</h4>
    <div v-show="!isReview" class="mb-2">
      <div class="columns">
        <div class="form-group column col-4 col-sm-12">
          <label class="form-label" for="minichallengeId">
            Minichallenge
          </label>
          <select
            id="minichallengeId"
            :class="[
              'form-select',
              { 'is-error': hasError('minichallengeId') },
            ]"
            v-model="minichallengeId"
          >
            <option v-for="mc in minichallenges" :value="mc.id" :key="mc.id">
              {{ mc.name }}
            </option>
          </select>
        </div>
        <div class="form-group column col-4 col-sm-12">
          <label class="form-label" for="username">AO3 Username</label>
          <input
            :class="['form-input', { 'is-error': hasError('username') }]"
            type="text"
            id="iusername"
            v-model="username"
            placeholder="AO3 Username"
          />
        </div>
        <div class="form-group column col-4 col-sm-12">
          <label class="form-label" for="username">
            Minichallenge Comment URL
          </label>
          <input
            :class="['form-input', { 'is-error': hasError('username') }]"
            type="text"
            id="letter"
            v-model="url"
            placeholder="http://..."
          />
        </div>
      </div>

      <div class="columns">
        <fandom-autocomplete
          :hasError="hasError('fandom')"
          v-for="i in max"
          :number="i"
          :fandoms="availableFandoms"
          :key="i"
          @update="update(i - 1, $event)"
          :reset="shouldReset"
        ></fandom-autocomplete>
      </div>
    </div>

    <template v-if="isReview && !added">
      <div class="error" v-if="userExists">
        This username has already been submitted to this minichallenge!
      </div>

      <table v-else class="table review mb-2">
        <tbody>
          <tr>
            <th>Minichallenge</th>
            <td>{{ getChallengeName(minichallengeId) }}</td>
          </tr>
          <tr>
            <th>Username</th>
            <td>{{ username }}</td>
          </tr>
          <tr>
            <th>Minichallenge Comment Link</th>
            <td>{{ url }}</td>
          </tr>
          <tr v-for="(fandom, i) in scrubbedFandoms" :key="fandom.name">
            <th>Fandom {{ i + 1 }}</th>
            <td>{{ fandom.fandom.name }}</td>
          </tr>
        </tbody>
      </table>
    </template>

    <div class="error list" v-if="errors.length">
      <p>Uh oh, looks like you need to fix some things...</p>
      <ul>
        <li v-if="hasError('minichallengeId')">
          You need to pick a minichallenge!
        </li>
        <li v-if="hasError('username')">You need a username</li>
        <li v-if="hasError('url')">
          You need a valid minichallenge link (including the starting http!)
        </li>
      </ul>
    </div>

    <button @click="submit" v-if="showSubmit" class="submit btn btn-primary">
      {{ submitText }}
    </button>
    <button
      v-if="isReview && showSubmit"
      @click="isReview = false"
      class="submit btn mx-2"
    >
      Edit
    </button>

    <!-- SUCCESS STATE -->
    <div v-show="added">
      <p>
        Successfully added {{ username }} to
        {{ getChallengeName(minichallengeId) }}!
        <button class="btn btn-link" @click="resetForm">
          Add another to the same challenge?
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { compact, each, includes, filter, find } from "lodash-es";
import FandomAutocomplete from "./minichallenge-autocomplete.vue";
import { minichallenges } from "../data/minichallengeMeta";

export default {
  components: {
    FandomAutocomplete
  },
  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  },
  beforeMount() {
    this.availableFandoms = this.fandoms;
    this.added = false;
  },
  data() {
    return {
      errors: [],
      max: 6,
      min: 1,
      username: "",
      selectedFandoms: [],
      scrubbedFandoms: [],
      minichallengeId: "",
      minichallenges,
      url: "",
      isReview: false,
      availableFandoms: [],
      userExists: false,
      showSubmit: true,
      added: false,
      number: undefined,
      shouldReset: false
    };
  },
  computed: {
    ...mapGetters(["fandoms", "characters", "isModIn", "letters", "unlock"]),
    submitText() {
      if (this.isReview) {
        return "Submit!";
      }

      return "Preview!";
    }
  },
  methods: {
    // https://stackoverflow.com/questions/8667070/javascript-regular-expression-to-validate-url
    validateUrl(value) {
      return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
        value
      );
    },
    getChallengeName(id) {
      if (!id || !id.length) return;
      return find(minichallenges, mc => mc.id == id).name;
    },
    resetForm() {
      this.errors = [];
      this.username = "";
      this.selectedFandoms = [];
      this.scrubbedFandoms = [];
      this.url = "";
      this.isReview = false;
      this.showSubmit = true;
      this.added = false;
      this.userExists = false;
      this.shouldReset = true;
    },
    submit() {
      this.errors = [];
      this.shouldReset = false;
      this.scrubFandoms();

      if (this.isReview) {
        this.add();
        return;
      }

      if (!this.minichallengeId.length) {
        this.errors.push("minichallengeId");
      }

      if (!this.username.length) {
        this.errors.push("username");
      }

      if (!this.url.length || !this.validateUrl(this.url)) {
        this.errors.push("url");
      }

      if (this.scrubbedFandoms.length < this.min) {
        this.errors.push("fandom");
      }

      if (this.errors.length) {
        return;
      }

      this.isReview = true;
    },
    scrubFandoms() {
      // put things into a separate array so that index order is preserved
      // if users want to go back and edit
      this.scrubbedFandoms = compact(
        filter(this.selectedFandoms, f => {
          return f !== null && f !== undefined;
        })
      );
    },
    edit() {
      this.isReview = false;
    },
    hasError(type) {
      return includes(this.errors, type);
    },
    update(index, data) {
      let newVal = this.selectedFandoms;

      if (!data.fandom || !data.fandom.name) {
        newVal[index] = null;
      } else {
        newVal[index] = data;
      }

      this.selectedFandoms = [];
      this.selectedFandoms = newVal;

      const selected = [];

      each(this.selectedFandoms, o => {
        if (!o) {
          return;
        }
        selected.push(o.fandom[".key"]);
      });

      this.availableFandoms = filter(this.fandoms, o => {
        return !includes(selected, o[".key"]);
      });
    },
    async add() {
      each(this.scrubbedFandoms, async req => {
        axios
          .get(
            `https://tagset2023-default-rtdb.firebaseio.com/minichallenges/${req.fandom[".key"]}/${this.minichallengeId}/${this.username}.json`
          )
          .then(async userReq => {
            if (userReq.data) {
              this.userExists = true;
              this.showSubmit = false;

              return false;
            }

            if (
              req.fandom[".key"] == undefined ||
              !this.minichallengeId ||
              !this.username
            ) {
              return false;
            }

            // Add user to each minichallenge
            await axios
              .put(
                `https://tagset2023-default-rtdb.firebaseio.com/minichallenges/${req.fandom[".key"]}/${this.minichallengeId}/${this.username}.json`,
                {
                  username: this.username,
                  url: this.url
                }
              )
              .then(() => {
                this.added = true;
                this.showSubmit = false;
              });
          });
      });
    }
  }
};
</script>

<style>
.fandom-autocomplete .chip {
  max-width: 100%;
}
</style>

<style lang="scss" scoped>
.fandom-autocomplete {
  margin: 10px 0;
  border-top: 1px solid #cfcfcf;
  padding: 10px 0;
}

table.review {
  td,
  th {
    border: 0;
    padding: 0;
  }
  th {
    vertical-align: top;
  }

  th {
    width: 15%;
  }
}

li {
  &:first-child {
    padding-top: 0;
    margin-top: 0;
  }
  margin-left: 25px;
  list-style-type: square;
}

.notices {
  margin: 10px 0;
}
</style>
